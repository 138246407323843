if (window.hideTabPanelsInitialized === undefined) {
  window.hideTabPanelsInitialized = true;

  const callback = function(e) {
    e.stopPropagation();

    const tab = e.target.closest('label');
    const tabsBlock = tab.closest('.js-tabs-tabs');
    const panelContainer = tabsBlock.querySelector('.js-tabs-panel-container');

    const tabs = Array.from(tabsBlock.querySelectorAll('.c-tabs-tab > label'));

    for (const tab of tabs) {
      if (e.target === tab) {
        continue;
      }

      tab.previousElementSibling.checked = false;
    }

    const panels = Array.from(panelContainer.querySelectorAll('.js-tabs-panel'));

    for (const panel of panels) {
      panel.classList.add('hidden');
    }

    panelContainer.querySelector(`div[data-tab=${tab.dataset.tab}]`).classList.remove('hidden');
  };

  const allTabs = Array.from(document.querySelectorAll('.c-tabs-tab > label'));

  for (const tab of allTabs) {
    tab.addEventListener('click', callback, false);
  }
}
